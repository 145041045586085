import { Carousel } from 'react-responsive-carousel';
import React, { useState } from 'react';
import SliderNav from '../components/slider/sliderNav';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { AppGatsbyImage, AppGatsbyImageFluid } from './appImage';
import useIntersectionObserverInitializer from '../hooks/useIntersectionObserverInitializer';
import { forceImageMaxWidth } from '../appGatsbyImageUtils';

type AppSliderImage = AppGatsbyImageFluid;

const AppSlider = ({
  className,
  images,
  onImageClick,
}: {
  className: string;
  images: AppSliderImage[];
  onImageClick?: (index: number, image: AppSliderImage) => void;
}) => {
  const initializer = useIntersectionObserverInitializer();
  const [index, setIndex] = useState(0);
  const prev = () => setIndex((index - 1) % images.length);
  const next = () => setIndex((index + 1) % images.length);

  // We don't need to put all gallery images in the dom until the slider enters viewport (including SSR output)
  const imagesFiltered = initializer.isInitialized ? images : [images[0]];

  const isAlmostVisibleSlide = (imageIndex: number) => {
    const isNearCurrentIndex =
      imageIndex >= index - 1 && imageIndex <= index + 1;
    return isNearCurrentIndex;
  };

  return (
    <div
      ref={initializer.ref}
      className={className}
      css={{
        img: {
          userSelect: 'none',
        },
        ...(onImageClick && {
          '.slide': {
            cursor: 'pointer',
            transition: 'all .3s ease-in-out',
          },
          '.slide:hover': {
            transform: 'scale(1.02)',
          },
        }),
      }}
    >
      <Carousel
        selectedItem={index}
        onChange={(i) => setIndex(i)}
        swipeable={true}
        emulateTouch={true}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        autoPlay={false}
        onClickItem={
          onImageClick
            ? (index) => onImageClick(index, images[index])
            : undefined
        }
        showArrows={false}
      >
        {imagesFiltered.map((fluidImage, i) => (
          <AppGatsbyImage
            key={`${i}`}
            // fluid={}
            fluid={forceImageMaxWidth(fluidImage, 681)}
            // TODO find a way to preload images around current slide
            // See https://github.com/gatsbyjs/gatsby/issues/16678
            loading={isAlmostVisibleSlide(i) ? 'eager' : 'lazy'}
            css={{
              width: '100%',
              height: '100%',
              backgroundColor: 'grey',
            }}
          />
        ))}
      </Carousel>
      <SliderNav prev={prev} next={next} />
    </div>
  );
};

export default AppSlider;
